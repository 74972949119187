import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import '../../scss/app.scss';
import Footer from './footer';
import Header from './header';
import Mobile from './mobile';

const Layout = ({ children, nav, pages }) => {
  const [open, setOpen] = useState(false);

  return (
    <main role="main" className="Main">
      {nav && <Header open={open} setOpen={setOpen} pages={pages} />}
      <Mobile open={open} setOpen={setOpen} />
      {children}
      {nav && <Footer />}
    </main>
  );
};

Layout.defaultProps = {
  nav: true,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  nav: PropTypes.bool,
};

export default Layout;
