import React from 'react';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__copyrights">
        <div>&copy; {new Date().getFullYear()} Services Plus</div>
        <div>
          Powered by <a href="https://flav.es">Flaves</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
