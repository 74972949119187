import React from 'react';

import DecisionMaking from '../svg/services/decision-making.svg';
import Guarantee from '../svg/services/guarantee.svg';
import HandShake from '../svg/services/handshake.svg';
import Running from '../svg/services/running.svg';

export default {
  en: {
    nav: {
      links: [
        {
          label: `Home`,
          path: `/en`,
        },
        {
          label: `Services`,
          path: `/en/services`,
        },
        {
          label: `Escales`,
          path: `/en/stopovers`,
        },
        {
          label: `Pricing`,
          path: `/en/pricing`,
        },
        {
          label: `Booking`,
          path: `/en/booking`,
        },
        {
          label: `Contact`,
          path: `/en/contact`,
        },
      ],
    },
    home: {
      assistanceTitle: `Assistance`,
      assistanceSub: `Services`,
      securityTitle: `Airport`,
      securitySub: `Security`,
      guardingTitle: `Security`,
      guardingSub: `Guarding`,
      discover: `Discover`,
      group: `Sicass Group`,
      coming: `Coming Soon`,
    },
    pricing: {
      seo: `Pricing`,
      title: `Our stopovers`,
      download: `Click on a destination to download the fee schedule.`,
      demand: `On demand`,
      contact: `Do not hesitate to contact us if you wish to book our services in one of the countries below.`,
    },
    services: {
      title: `SERVICES PLUS GUARANTEES YOU A UNIQUE, HIGH QUALITY AND COMFORTABLE SERVICE. `,
      coreBusinessTitle: `OUR CORE BUSINESS`,
      coreBusinessItems: [
        { title: `Reliability`, icon: <HandShake /> },
        { title: `Versatility`, icon: <DecisionMaking /> },
        { title: `Dynamism`, icon: <Running /> },
        { title: `Quality`, icon: <Guarantee /> },
      ],
      services: `OUR SERVICES`,
      reservations: `RESERVATIONS`,
      slogan: `In order to respond to the realities of the market, the agency is committed to send you a competitive and accurate quote. You can transparently control your costs, without commitment.`,
    },
    booking: {
      seo: `Booking`,
      private: `Private Individual`,
      pro: `I am part of a company`,
      alreadyContract: `I already have a contract with Services Plus`,
      subsidiaryGroup: `I have a contract with another subsidiary of the group`,
      companyLabel: `I am part of a company and I have a contract with Services Plus`,
      company: `Company`,
      date: `Date`,
      hour: `Hour`,
      flightNumber: `Flight Number`,
      airline: `Airline`,
      destination: `Destination`,
      services: `Pick your services`,
      servicesLabel: `Do not hesitate to specify your request for existing services or any other specific request.`,
      informations: `Informations`,
      accept: `By submitting this form, I accept that the information entered will be used in the context of my request and the commercial relationship that may result therefrom.`,
      next: `Next`,
      summary: `Summary`,
    },
    contact: {
      title: `Contact us`,
      info: `Please choose the country in which you want to be accompanied.`,
    },
    form: {
      firstName: `Firstname`,
      lastName: `Lastname`,
      email: `Email`,
      phone: `Phone Number`,
      place: `Place of takeover`,
      message: `Message`,
      send: `Send`,
      required: `This field is required.`,
    },
    stopover: {
      seo: `Stopovers`,
      title: `Our stopovers`,
      heading: `Do not hesitate to contact us if you wish to book our services in one of the countries below.`,
    },
  },
  fr: {
    nav: {
      links: [
        {
          label: `Accueil`,
          path: `/fr`,
        },
        {
          label: `Services`,
          path: `/fr/services`,
        },
        {
          label: `Escales`,
          path: `/fr/escales`,
        },
        {
          label: `Tarification`,
          path: `/fr/tarification`,
        },
        {
          label: `Réservation`,
          path: `/fr/reservation`,
        },
        {
          label: `Contact`,
          path: `/fr/contact`,
        },
      ],
    },
    home: {
      assistanceTitle: `SERVICES`,
      assistanceSub: `D'ASSISTANCE`,
      securityTitle: `SÛRETÉ`,
      securitySub: `AÉROPORTUAIRE`,
      guardingTitle: `SÉCURITÉ`,
      guardingSub: `GARDIENNAGE`,
      discover: `Découvrir`,
      group: `Groupe Sicass`,
      coming: `À venir`,
    },
    pricing: {
      seo: `Tarification`,
      title: `Nos escales`,
      download: `Cliquez sur une destination pour télécharger la grille tarifaire.`,
      demand: `Sur demande`,
      contact: `N'hésitez pas à nous contacter si vous désirez réserver nos services dans l'un des pays ci-dessous.`,
    },
    services: {
      title: `SERVICES PLUS VOUS GARANTIT UNE PRESTATION DE SERVICE UNIQUE, DE QUALITÉ ET DE CONFORT.`,
      coreBusinessTitle: `NOTRE ENGAGEMENT`,
      coreBusinessItems: [
        { title: `FIABILITÉ`, icon: <HandShake /> },
        { title: `POLYVALENCE`, icon: <DecisionMaking /> },
        { title: `DYNAMISME`, icon: <Running /> },
        { title: `QUALITÉ`, icon: <Guarantee /> },
      ],
      services: `NOS SERVICES`,
      reservations: `RÉSERVATION`,
      slogan: `"Afin de répondre aux réalités du marché, l'agence s'engage à vous faire parvenir un devis compétitif et précis. Vous pouvez en toute transparence maîtriser vos coûts, et cela sans engagement."`,
    },
    booking: {
      seo: `Réservation`,
      private: `Je suis un privé`,
      pro: `Je fais partie d'une société`,
      alreadyContract: `J'ai déjà un contrat avec Services Plus`,
      subsidiaryGroup: `J'ai un contrat avec une filiale du groupe`,
      companyLabel: `Je fais partie d'une société et j'ai un contrat avec Services Plus`,
      company: `Société`,
      date: `Date`,
      hour: `Heure`,
      flightNumber: `NUMÉRO DE VOL`,
      airline: `COMPAGNIE AÉRIENNE`,
      destination: `PAYS DE DESTINATION`,
      services: `CHOISISSEZ VOS SERVICES`,
      servicesLabel: `N'hésitez pas à préciser votre demande pour les services existants ou toute autre demande particulière.`,
      informations: `INFORMATIONS`,
      accept: `En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de ma demande et la relation commercial qui peut en découler.`,
      next: `Suivant`,
      summary: `Résumé`,
    },
    contact: {
      title: `Contact`,
      info: `Veuillez choisir le pays dans lequel vous voulez être pris en charge.`,
    },
    form: {
      firstName: `Prénom`,
      lastName: `Nom`,
      email: `Email`,
      phone: `Téléphone`,
      place: `Lieu de prise en charge`,
      message: `Message`,
      send: `Envoyer`,
      required: `Ce champ est requis.`,
    },
    stopover: {
      seo: `Escales`,
      title: `Nos escales`,
      heading: `N'hésitez pas à nous contacter si vous désirez réserver nos services dans l'un des pays ci-dessous.`,
    },
  },
};
