import { Link } from 'gatsby';
import React from 'react';
import { animated as a, useSpring } from 'react-spring/web.cjs';
import useTranslation from '../../hooks/useTranslation';

const Mobile = ({ open }) => {
  const translate = useSpring({
    transform: open ? `translateY(0%)` : `translateY(-100%)`,
  });
  const t = useTranslation();

  const renderLinks = () => (
    <ul className="Mobile__list">
      {t(`nav.links`).map((link, index) => (
        <li key={index}>
          <Link to={link.path} activeClassName="active">
            {link.label}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <a.nav className="Mobile" style={translate}>
      {renderLinks()}
    </a.nav>
  );
};

export default Mobile;
