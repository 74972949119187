import { useSelector } from 'react-redux';

import translations from '../data/translations';

const useTranslation = () => {
  const { locale } = useSelector(state => state.i18n);

  return (key = ``) => {
    const parts = key.split(`.`);

    const page = parts[0];
    const message = parts[1];

    return translations[locale][page][message];
  };
};

export default useTranslation;
