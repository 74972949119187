import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { animated as a, useSpring } from 'react-spring/web.cjs';
import classNames from 'classnames';

import { SWITCH_LANGUAGE } from '../../actions/types';

import useTranslation from '../../hooks/useTranslation';

import Logo from '../../svg/logo/services-plus.svg';

const Header = ({ open, setOpen, pages }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const t = useTranslation();
  const sticky = useSpring({
    backgroundColor: active
      ? `rgba(255, 255, 255, 1)`
      : `rgba(255, 255, 255, 0)`,
  });
  const { locale } = useSelector(state => state.i18n);

  const onScroll = () => {
    if (window.scrollY > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll);

    return () => window.removeEventListener(`scroll`, onScroll);
  });

  const onChange = async e => {
    const locale = e.target.value;

    dispatch({
      type: SWITCH_LANGUAGE,
      locale,
    });
    await navigate(`/${pages[locale]}`);
  };

  const renderLinks = () => (
    <ul className="Header__nav__list">
      {t(`nav.links`).map((link, index) => (
        <li key={index}>
          <Link to={link.path} activeClassName="active">
            {link.label}
          </Link>
        </li>
      ))}
      <li>
        <select
          className="Header__lang"
          onChange={onChange}
          defaultValue={locale}
        >
          <option value="en">EN</option>
          <option value="fr">FR</option>
        </select>
      </li>
    </ul>
  );

  return (
    <a.header className="Header" style={sticky}>
      <div className="Header__container">
        <figure className="Header__logo" onClick={() => navigate(`/`)}>
          <Logo />
        </figure>
        <nav className="Header__nav">{renderLinks()}</nav>
        <div
          className={classNames(`Header__trigger`, {
            [`Header__trigger--open`]: open,
          })}
          onClick={() => setOpen(!open)}
        >
          <span />
        </div>
      </div>
    </a.header>
  );
};

export default Header;
